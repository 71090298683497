#AboutBox3{
    width: 100%;
    height: 100vh;
    display: flex; 
    justify-content: center; 
    align-items: center; 

}
#AboutBox3 video{
    position: absolute;
    object-fit: cover;
    width: 100%;
    z-index: -1;
}
#containerform{
    width: 90%;
    height: 70%;
    
}
#Abouticon_info{
   display: flex;
   width: 90%;
   margin: 0 auto;
   
  
}
#Abouticon{
    font-size: 300%;
    color:#0061a8;
    opacity: 0; 
    animation: itemicon 1s linear 1 forwards 1.25s; 
    font-size:clamp(1.5rem,2vw,3rem); 
    display: flex; 
    justify-content: center; 
    align-items: center; 
}
#Abouticon div{
    width: 50%; 
}

@keyframes itemicon {
    0%{
      transform: translateX(0%);
    }
    100%{
      opacity: 1;
      transform: translateX(-25%);
      }
    }

.AbouticonItem:hover {
    font-size: 2.2rem;
    color: #7ab2dd;
}
#Aboutinfo{
    width: 100%;
    background-color: #ffffff;
    box-shadow: -13px -13px 30px #cbced1;
    overflow: hidden;
    display: flex;
    animation: item 1s linear 1 forwards;

}
@keyframes item  {
    0%{
      transform: translateX(50%);
    }
    100%{
      transform: translateX(0%);
      }
    }
#content{
    width: 60%;
    background-color: white;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 0 auto;
  
}
#content_fils{
    width: 80%;

}
#map{
    width: 40%;
    background-image: url(../../assets/images/map.png); 
    background-repeat: no-repeat;
    background-size: cover;
}
#mapfils{
    width: 100%;
    background-color:rgba(14, 46, 65,0.5);
    box-sizing: border-box;
    height: 100%;
    border-right: 9.375rem solid white;
    border-bottom: 31.25rem solid transparent;
    
}
#BoxHeaddding1{
margin-bottom: 4%;
}
#BoxHeaddding1 h1{
    font-size:clamp(1.5rem,2vw,5rem);
    color: #0e2e41;
    animation: Headding 0.5s ease-in-out 0.5 0s ;
}
#BoxHeaddding1Icon{
    font-size:clamp(1.7rem,3vw,5rem);
    margin-left: 3%;
    animation: BoxHeaddding1Icon 1s linear infinite forwards;
}
@keyframes BoxHeaddding1Icon {
    0%{
      transform: rotate(20deg);
    }
    50%{
        transform: rotate(-20deg);
      }
    100%{
      transform: rotate(20deg);
      }
    }
#BoxHeaddding1 p{
    font-size:clamp(0.75rem,1.1vw,3rem);
    color: #0e2e41;
    animation: Headding 0.5s ease-in-out 1 forwards 0.5s ;
    opacity: 0;
    margin-bottom: 2%;
}
#content h1{
    text-align: center;
    font-size:clamp(1.3rem,2vw,3rem);
    color: #0e2e41;
    margin-bottom: 5%;

    
}
#content span,#content input,#content label {
    font-size:clamp(0.75rem,1vw,3rem);
}
@keyframes Headding{
    0%{
      transform: translateX(-100%);
    }
    100%{
      opacity: 1;
      transform: translateX(0%);
      }
    }
#contensIcons{
    display: flex;
    width: 100%;
    gap:2%;
    justify-content: center;
}
#content img{
    width: 50%;
    margin: 0 auto;
}
.submitform{
    background-color: #0061a8;
    color: #ffffff;
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    margin-top: 4.5%;
    margin-bottom: 11%;
    font-size:clamp(0.75rem,1vw,3rem);
}
.submitform:hover{
    background-color: #7ab2dd;
    font-size:clamp(0.8rem,1.1vw,3rem);
}
.AboutBoxinfo2Item{
    display: flex;
    font-size:clamp(0.5rem,1vw,3rem);
   
}

@media (max-width:700px) {

    #Abouticon_info,#Aboutinfo{
        display: inline;   
    }
    #Abouticon{
        margin-left: 40% ;
     
        width: 50%;
    }
    #Abouticon div{
       
        display: flex;
        width: 100%;
        justify-content: space-between;

    }
    #map{
     height: 20%;
     width: 90%;
     margin: 0 auto;
     background-image: url(../../assets/images/mapCopie.png); 
    background-repeat: no-repeat;
    background-size: cover;
 
    }
    #mapfils{
        border: none;
        overflow: hidden;
        position: relative;
    }
    #mapfils div {
       width: 120%;
       height: 55%;
       position: absolute;
       bottom: -40%; 
       left: -10%; 
       background-color: #ffffff;
       transform: rotate(7deg);
    }


    #content_fils{
        width: 100%;
        /* background-image:linear-gradient(180deg,rgba(246,250,252,0.65) 30%, rgba(14, 46, 65,0.4)); */
        padding-inline: 4%;
        padding-bottom: 4%;
    }
    #content{
        width: 90%;
        
    }
    
    

    
}
