.outer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards .card {
  background: #F2F2F2;
  padding: 1.5rem;
  max-width: 30rem;
  border-radius: 0.5rem;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  animation: animate 15s infinite linear;
  animation-delay: calc(3s * var(--delay));
}

.header {
  justify-content: space-between;
}
.profile {
  margin-bottom: 0.5rem;
}

.profile .details {
  margin-left: 10px;
}
.op-star {
  color: #28223f;
  font-size: 1.1rem;
}

.outer:hover .card {
  animation-play-state: paused;
}
.cards .card:last-child {
  animation-delay: calc(-3s * var(--delay));
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(100%) scale(0.5);
  }
  5%,
  20% {
    opacity: 0.4;
    transform: translateY(100%) scale(0.7);
  }
  25%,
  40% {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0%) scale(1);
  }
  45%,
  60% {
    opacity: 0.4;
    transform: translateY(-100%) scale(0.7);
  }
  65%,
  100% {
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
  }
}


@media (max-width: 467px) {
  /*Edited*/
  .card {
    font-size: 10px;
    padding: 0.5rem !important;
    margin: 0.5rem;
    text-align: center;
  }
  .header,
  .profile {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
}
