

.Color{
  color:#1D202B !important;
}
.div10 {
  display: flex;
  flex-direction: column;
  width: 340px;
  margin-top: 5px;
}
.div110 {
  display: flex;
  flex-direction: column;
  width: 240px;
  margin-top: 5px;
}
#titre_gods30{
  font-family: 'Poppins-Regular', sans-serif;
  font-size: 160%;
  color:#B0BDC1;
  font-weight: bold;
  padding-left: 50px;
  padding-top: 10px;
}

.div11{
  margin-top: 20px;
  width: 99%;
  display: grid;
  grid-template-columns: 40% 59% ;
  align-items: center;
}
#img_gods30{
  width: 90%;
  margin: 20px;
  padding-left: 10px;
  padding-bottom: 10px;
}
#para1{
  font-size: 100%;
  padding-left: 10px;
  text-align: center;
  font-family: 'Poppins-Regular', sans-serif;
  margin-right: 15px;
}



.div20{
  float: right;
  display: flex;
  flex-direction: column;
  width: 340px;
  margin-top: 5px;
  margin-right: 150px;
}
#titre_gods20{
  font-family: 'Poppins-Regular', sans-serif;
  font-size: 160%;
  color:#B0BDC1;
  font-weight: bold;
  padding-right: 25px;
  padding-top: 10px;
  padding-left: 20px;
}
.vec2{
  height:5px;
  padding-right: 30px;
  width: 100%;
  height: 5px;
  padding-top: 5px;
}
.div21{
  margin-top: 20px;
  width: 99%;
  display: grid;
  grid-template-columns: 59% 40% ;
  padding-top:10px;
  align-items: center;
}
#img_gods20{
  width: 95%;
  margin: 10px;
}
#para2{
  font-size: 100%;
  font-family: 'Poppins-Regular', sans-serif;
  margin-right: 20px;
}


#counter1{
  float:center;
  font-size: 20px;
  width: 100%;

}
.flex_cont{
  display: grid ;
  grid-template-columns: 33% 33% 33% ;
  margin: auto;
  font-family: 'Poppins-Regular', sans-serif;
  text-align: center;    
  padding-bottom: 20px;
}
.circless {
  display: inline-block;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #1D1F2B;
  text-align: center;
  line-height: 100px;
  color:#B0BDC1 ;
  box-shadow: 0  5px 5px rgb(94, 100, 117);
  
}
.titre_cercle{
  font-weight: bold;
  float: center;
  color:#1D1F2B;
}
#text3{
  text-align: center;
  color:#1D1F2B;
  font-family: 'Poppins-Regular', sans-serif;
  font-weight: bold;
  font-size: 120%;
}


@media screen and (max-width: 767px) {
  .div11, .div21 {
    grid-template-columns: 100%; /* Full width for small screens */
    align-items:center;
  }
  .div110 {
    display: flex;
    flex-direction: column;
    width: 210px;
    margin-top: 5px;
  }
  #img_gods30, #img_gods20 {
    width: 95%; /* Full width for small screens */
    margin: 2.5% 0; /* Adjust margin for better spacing */

  }

  #img_gods20{
    padding-left: 2.5%;
  }
  .div21{
    width: 99%;
    display: flex;
    grid-template-columns: 59% 40% ;
    align-items: center;
  }
  #titre_gods30, #titre_gods20 {
    font-size: 150%; /* Adjust font size for better readability on small screens */
  }

  #para1, #para2 {
    font-size: 100%; /* Adjust font size for better readability on small screens */
  }

  .titre_cercle {
    font-size: 100%; /* Adjust font size for better readability on small screens */
  }

  #text3 {
    font-size: 120%; /* Adjust font size for better readability on small screens */
  }
}
