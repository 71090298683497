@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
.title{
    font-family: 'Open Sans', sans-serif;
    font-weight:700;
    font-size:18px;
    padding: 16px 16px 4px;
    display:flex;
    align-items:flex-start;
}
p{
    font-family: 'Poppins', sans-serif;
    font-weight:400;
}
