.tit{
    color:#0C0D26;
    letter-spacing:3px;
    text-transform:uppercase;
    -webkit-text-stroke:0.35px #F2F2F2;
    opacity:1;
}
.main-title{
    filter: blur(10px);
    opacity:0;
    transition: all 1s;
    transform:translateY(-10%);
}
.about-title{
    filter: blur(10px);
    opacity:0;
    transition: all 1s;
    transform:translateX(-100%);
}


.about-container{
    animation: about-anim 1s;
}
@keyframes about-anim {
    0%{
        filter: blur(5px);
        opacity:0;
        transform: translateY(30%);
    }
    100%{
        filter: blur(0);
        opacity:1;
        transform: translateY(0);
    }
}
.about-image{
    animation: image-anim 1s;
}
@keyframes image-anim {
    0%{
        filter: blur(5px);
        transform: scale(0.9);
    }
    100%{
        filter: blur(0);
        transform: scale(1);
    }
}
.Cs-image{
    animation: image-cs 1s;
}
@keyframes image-cs{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}

.containeer{
    opacity:0;
    display:hidden;
    transition: all 1s;
    filter: blur(5px);
    transform: translateX(-100%);
}
.containeerr{
    opacity:0;
    position:relative;
    display:hidden;
    transition: all 1s;
    filter: blur(5px);
    transform: translateX(50%);
}
.cont{
    opacity:0;
    transition: all 1s;
    filter: blur(5px);
    transform: translateY(20%);
}
.show{
    opacity:1;
    filter: blur(0);
    transform: translateX(0);
    transform: translateY(0);
    
}
/* Style de la ligne horizontale */
.horizontal-line1 {
    position: relative;
  }
  
  .horizontal-line1::before,
  .horizontal-line1::after {
    content: "";
    position: absolute;
    top: 50%;
    margin-left:-40px;
    margin-right:-40px;
    width: 45%;
    height: 1px; /* Épaisseur de la ligne */
    background-color: rgb(153 27 27 / var(--tw-bg-opacity)); /* Couleur de la ligne */
  }
  
  .horizontal-line1::before {
    left: 0;
  }
  
  .horizontal-line1::after {
    right: 0;
  }
  @media (min-width:640px) and (max-width:1024px){
    .horizontal-line1::before,
    .horizontal-line1::after {
      width: 43%;
    }
  }
  @media (max-width:640px){
    .horizontal-line1::before,
    .horizontal-line1::after {
      width: 20%;
    }
  }