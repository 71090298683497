/* BackToTopButton.css */

.back-to-top-button {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding:15px;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .back-to-top-button.visible {
    display: block;
  }
  
  .back-to-top-button:hover {
    background-color: #0056b3;
  }
  