:root {
  --background-dark: #2d3548;
  --text-light: rgb(255, 235, 13);
  --text-lighter: rgb(255, 255, 255);
  --spacing-s: 8px;
  --spacing-m: 16px;
  --spacing-l: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 64px;
  --width-container: 1200px;
}

.hero-section {
  align-items: flex-center;
  width: 80%;
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-l);
}

.cardPopular-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-l);
  grid-row-gap: var(--spacing-l);
  max-width: var(--width-container);
  width: 80%;
}

@media (min-width: 540px) {
  .cardPopular-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .cardPopular-grid {
    grid-template-columns: repeat(3, 1fr);
    width: 60%;
  }
}

.cardPopular {
  list-style: none;
  position: relative;
}

.cardPopular:before {
  content: '';
  display: block;
  padding-bottom: 177.78%; /* 16:9 aspect ratio for 1080x1920 images */
  width: 100%; /* Ensure it scales correctly */
}


.cardPopular__background {
  background-size: cover;
  background-position: cover;
  border-radius: var(--spacing-l);
  bottom: 0;
  left: 0;
  position: absolute;
  background-size: cover;
  right: 0;
  top: 0;
  transform-origin: center;
  transform: scale(1) translateZ(0);
  transition: filter 200ms linear, transform 200ms linear;
}

.cardPopular:hover .cardPopular__background {
  transform: scale(1.05) translateZ(0);
  filter: ;
}

.cardPopular-grid:hover > .cardPopular:not(:hover) .cardPopular__background {
  filter: brightness(0.5) saturate(0.4) contrast(1.2) blur(10px);
}

.cardPopular__content {
  left: 0;
  padding: var(--spacing-l);
  position: absolute;
  top: 0;
}

.cardPopular__category {
  color: var(--text-light);
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.cardPopular__heading {
  color: var(--text-lighter);

  font-size: 1.9rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  line-height: 1.4;

  word-spacing: 100vw;
}
