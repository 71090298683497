@font-face {
    font-family: 'HPFont';
    src: url(Harry.TTF);
  }
  @font-face {
    font-family: 'Owl';
    src: url(magic.otf);
  }
.HP{
    font-family: 'HPFont';
}
.Owl{
    font-family: 'Owl';
}
.cstitle{
    font-size : 5rem;
}
@media(max-width : 750px){
    .cstitle{
        font-size: 3.5rem;
    }
}
.header-image{
    background-image: url("https://res.cloudinary.com/dvw61yp2y/image/upload/v1712636479/Chapters/Harry_ank8vy.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.imgxtreme{
    box-shadow: -3px 3px 73px -11px rgba(255,255,255,0.20);
    -webkit-box-shadow: -3px 3px 73px -11px rgba(255,255,255,0.2);
    -moz-box-shadow: -3px 3px 73px -11px rgba(255,255,255,0.20);
}
.Himg{
    opacity:0.3 !important;
}
.back1{
  -webkit-filter: blur(5px);
}
.back2{
  -webkit-filter: blur(3px);
}
.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%, 
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}
.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}
.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}
* {
  scroll-behavior: smooth;
}