@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
*{
    font-family: 'Poppins', sans-serif;
    font-weight:500;
    margin:0;
    padding:0;
    scroll-behavior: smooth;
}

html::-webkit-scrollbar{
    width:10px;
}
html::-webkit-scrollbar-thumb {
    background-color: #E1E1E9;
    border-radius:20px;
  }
  
html::-webkit-scrollbar-track {
   background-color: transparent;
}

  
  .rotate-45 {
    @apply rotate-[45deg]
  }
  
  .rotate-90 {
    @apply rotate-[90deg]
  }
  
  .rotate-135 {
    @apply rotate-[135deg]
  }
  
  .rotate-180 {
    @apply rotate-[180deg]
  }
  
  .rotate-225 {
    @apply rotate-[225deg]
  }
  
  .rotate-270 {
    @apply rotate-[270deg]
  }
  
  .rotate-315 {
    @apply rotate-[315deg]
  }
  
  .rotate-360 {
    @apply rotate-[360deg]
  }
  
  .-rotate-45 {
    @apply rotate-[-45deg]
  }
  
  .-rotate-90 {
    @apply rotate-[-90deg]
  }
  
  .-rotate-135 {
    @apply rotate-[-135deg]
  }
  
  .-rotate-180 {
    @apply rotate-[-180deg]
  }
  
  .-rotate-225 {
    @apply rotate-[-225deg]
  }
  
  .-rotate-270 {
    @apply rotate-[-270deg]
  }
  
  .-rotate-315 {
    @apply rotate-[-315deg]
  }
  
  .-rotate-360 {
    @apply rotate-[-360deg]
  }
  
  
  .text-gradient {
    background: radial-gradient(
      64.18% 64.18% at 71.16% 35.69%,
      #def9fa 0.89%,
      #bef3f5 17.23%,
      #9dedf0 42.04%,
      #7de7eb 55.12%,
      #5ce1e6 71.54%,
      #33bbcf 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .text-gradient-orange {
    background: radial-gradient(
      64.18% 64.18% at 71.16% 35.69%,
      #FFA500 0.89%,
      #FF8C00 17.23%,
      #FF7F50 42.04%,
      #FF6347 55.12%,
      #FFD700 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .text-gradient-pink {
    background: radial-gradient(
      64.18% 64.18% at 71.16% 35.69%,
      #FFC0CB 0.89%,
      #FFB6C1 17.23%,
      #FF69B4 42.04%,
      #DB7093 71.54%,
      #C71585 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .text-gradient-orange-pink {
    background: radial-gradient(
      64.18% 64.18% at 71.16% 35.69%,
      #FFA500 0.89%,
      #FF8C00 17.23%,
      #FF69B4 42.04%,
      #FF1493 55.12%,
      #DB7093 71.54%,
      #C71585 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  
  
  .bg-blue-gradient {
    background: linear-gradient(
      157.81deg,
      #def9fa -43.27%,
      #bef3f5 -21.24%,
      #9dedf0 12.19%,
      #7de7eb 29.82%,
      #5ce1e6 51.94%,
      #33bbcf 90.29%
    );
  }
  
  .bg-orange2-gradient {
    background: linear-gradient(
      120deg,
      #FFA500 0%,
      #FF8C00 50%,
      #FF7F50 100%
    );
  }
  
  .bg-black-gradient {
    background: linear-gradient(
      144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%
    );
  }
  
  .bg-black-gradient-2 {
    background: linear-gradient(
      -168.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%
    );
  }
  
  .bg-gray-gradient {
    background: linear-gradient(
      153.47deg,
      rgba(255, 255, 255, 0) -341.94%,
      #14101d 95.11%
    );
  }
  
  .bg-discount-gradient {
    background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
  }
  
  .box-shadow {
    box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  }
  
  .sidebar {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  
  .feature-card:hover {
    background: var(--black-gradient);
    box-shadow: var(--card-shadow);
  }
  
  .feedback-container .feedback-card:last-child {
    margin-right: 0px;
  }
  
  .feedback-card {
    background: transparent;
  }
  
  .feedback-card:hover {
    background: var(--black-gradient);
  }
  
  .blue__gradient {
    background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
    filter: blur(123px);
  }
  
  .pink__gradient {
    background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
    filter: blur(900px);
  }
  
  .white__gradient {
    background: rgba(255, 255, 255, 0.6);
    filter: blur(750px);
  }